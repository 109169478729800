<template>
  <div class="vendor">

    <div v-if="showTable">

      <div style="margin-top:10px">

        <div style="margin-bottom:10px">
          <v-row>
            <v-col cols="3">
              <v-text-field label="Search..." variant="solo" density="compact" hide-details="true" @input="debouncedSearch($event)" style=""></v-text-field>
            </v-col>
          </v-row>
        </div>

        <Vue3EasyDataTable
          :headers="headers"
          :items="customer_backorders"
          :search-value="searchValue"
          :sort-by="sortBy"
          :sort-type="sortType"
          :hide-footer="false"
          :rows-per-page="5000"
          :rows-items="[250, 500, 1000, 5000]"
          :hide-rows-per-page="true"
          :table-height="table_height"
          :empty-message="data_message"
        >


          <template #item-customer_name="item">
            <router-link :to="{ name: 'customer', params: {customerpageid: db.customers['customer_'+item.customer_number].page}}" target="_blank" style="color:#ff7214;font-weight:bold">{{item.customer_name}}</router-link>
          </template>

          <template #item-sortable_ship_date="item">
            {{item.ship_date}}
          </template>
 

        </Vue3EasyDataTable>

      </div>

    </div>
    <span class="version-footer">
      {{$store.state.version}}
    </span>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { debounce } from 'lodash';


import XLSX from 'xlsx'

export default {
  name: 'ShipmentTracker',
  components: {Vue3EasyDataTable},
  data(){
    return {
      table_height: 1200,
      searchValue: '',
      sortBy: 'customer_name',
      sortType: 'desc',
      showTable: false,
      data_message: 'No data',
      order_writers_arr: ['All'],
      selectedOrderWriter: 'All'
    }
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },
  beforeMount(){
    var self = this
    self.$store.state.isLoading = true
    setTimeout(() => {
      self.showTable = true;
      self.$store.state.isLoading = false
    }, 2000);
  },
  computed: {
    ...mapGetters(['user','db','po_confirmation_status','item_eta_status']),
    lastUploadDate(){
      return Moment(this.db.last_backorder_file_upload).format('MM/DD/YY hh:mma')
    },
    headers(){
      return [
        { text: "Riverhawk Sales Order Number", value: "order_number", sortable: true },
        { text: "Customer Name", value: "customer_name", sortable: true },
        { text: "Order Date", value: "order_date", sortable: true },
        { text: "Shipping Company", value: "shipping_company", sortable: true },
        { text: "Tracking Number", value: "tracking_number", sortable: true },
        { text: "Order Writer", value: "order_writer_name", sortable: true },                
        { text: "Dropship?", value: "dropship", sortable: true },
        { text: "Ship Date", value: "sortable_ship_date", sortable: true },
        { text: "Order Status", value: "complete_order_status", sortable: true },
        { text: "Line Item Warehouse", value: "line_item_warehouse", sortable: true },
        { text: "Ship Via Code", value: "ship_via_code", sortable: true },
      ]
    },
    customer_backorders(){
      var backorders_db_clone = this.db.backorders_for_shipment_tracker ? JSON.parse(JSON.stringify(this.db.backorders_for_shipment_tracker)) : {}
      var backorders = []
      var lines_used = []
      for(var b in backorders_db_clone){
        var backorder = backorders_db_clone[b]
        backorder.id = b
        backorder.dropship = backorder.line_item_warehouse === '09' ? 'YES' : 'NO'

        backorder.sortable_ship_date = backorder.ship_date ? Moment(backorder.ship_date).unix() : ''

        var line_id = backorder.order_number+':'+backorder.line_item_warehouse
        var match_line_warehouse = backorder.line_item_warehouse === '09'
        var match_ship_via_code = ['08','10','11','14','15','16','17','VMI+'].indexOf(backorder.ship_via_code) !== -1

        var match_complete_order_status_s = backorder.complete_order_status.split(',').indexOf('S') !== -1
        var match_complete_order_status_d = backorder.complete_order_status.split(',').indexOf('D') !== -1

        if((match_line_warehouse || match_ship_via_code) && (match_complete_order_status_s || match_complete_order_status_d) && lines_used.indexOf(line_id) === -1){
          backorders.push(backorder);
          lines_used.push(line_id)
        }
      }
      return backorders
    }
  },
  methods: {
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 200
    },
    debouncedSearch: debounce(function(event) {
      this.searchValue = event.target._value.replace(/\*/g, "");
    }, 500),
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    }
  }
}
</script>

<style scoped>

  .vendor{
    margin: 20px;
  }

  table{
    font-size: 12px;
  }
  th {
    text-align: left;
    padding-right: 20px;
  }
  td {
    text-align: left;
    padding-right: 20px;
  }

  .table-container{
    padding: 15px;
  }

  .table-title{
    padding-top: 15px;
  }

  .critical_btn{
    border: 1px solid #ff7214;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .critical_btn_active{
    background: #ff7214;
    border: 1px solid #ff7214;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 16px;
  }

  .critical_btn_active::after{
      content: "✔";
  }

  .riverhawk-header{
    background: #ff7214;
    height: 40px;
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
  }

  .riverhawk-header-text{
    color: white;
    font-weight: bold;
    padding: 5px;
  }

.status-complete {
    color: green;
    width: 70px;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    margin-left: 5px;
}

.status-due-soon {
    color: #FFA500;
    width: 70px;
    text-align: center;
    font-weight: bold;
    border-radius: 2px;
    font-size: 12px;
    margin-left: 5px;
}

.status-due-now {
    color: red;
    width: 70px;
    text-align: center;
    font-weight: bold;
    border-radius: 2px;
    font-size: 12px;
    margin-left: 5px;
}
</style>
