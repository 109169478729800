import axios from 'axios'
import firebase from 'firebase/compat/app';
import Moment from 'moment'

export const emailMixin = {
  data(){
    return{
      url: 'https://us-central1-riverhawk-nest-production.cloudfunctions.net/sendNestEmail',
      vendor_url: 'https://riverhawknest.com/vendor/',
      // url: 'https://us-central1-riverhawk-nest-development.cloudfunctions.net/sendNestEmail',
      // vendor_url: 'http://localhost:8080/vendor/',
      from: 'apps@riverhawkindustrialsupply.com',
      dispute_contact: 'purch@riverhawkindustrialsupply.com;bmay@riverhawkindustrialsupply.com',
      shared_note_contact: 'purch@riverhawkindustrialsupply.com',
      utp_contact: 'purch@riverhawkindustrialsupply.com'
    }
  },
  methods:{
    etaRequestEmail(email){
      // ItemDetailPopUp.vue
      var timestamp = Date.now()
      var today_string = Moment(timestamp).format('YYYY-MM-DD')

      if(email.attempt < 2){
        var subject = 'ETA request - ' + email.vendor
        var html = 'Hello, your product has not arrived. Please provide updated ETA by ' + '<a href="' + this.vendor_url + email.vendor_page + '">clicking here</a>' + '. DO NOT REPLY.<br><br>Thanks,<br><br>Tracking Team<br>Riverhawk Industrial Supply'
      }else{
        var subject = 'ESCALATION: ETA request - ' + email.vendor
        var html = 'ESCALATION: Hello, we are reaching out again. Your product has not arrived. Please provide updated ETA by ' + '<a href="' + this.vendor_url + email.vendor_page + '">clicking here</a>' + '. DO NOT REPLY.<br><br>Thanks,<br><br>Tracking Team<br>Riverhawk Industrial Supply'
      }

      var to_address_arr = email.to.replace(/ /g, '').split(';')
      var email_to_no_duplicates_arr = to_address_arr.filter(function(item, pos) {
          return to_address_arr.indexOf(item) == pos && item.indexOf('emailmissing') == -1;;
      })
      email.to = email_to_no_duplicates_arr.join(';')

      if(email.to === ''){
        firebase.database().ref('email_failure_log').child(today_string).push({email: email, timestamp: Date.now(), method: 'eta_request_from_item_popup', error: 'email missing'})
        return
      }

      var encodedSubject = encodeURIComponent(subject);
      var encodedHtml = encodeURIComponent(html);
      var url = this.url+'?to=' + email.to + '&from=' + this.from +'&subject='+encodedSubject + '&html='+encodedHtml

      axios.post(url, {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'})
      .then(function (response) {
        if(response.data.success){
          console.log(response.data.success.message)
          for(var i = 0; i < email.item_keys.length; i++){
            var item_key = email.item_keys[i]
            firebase.database().ref('purchase_orders').child(email.po).child('items').child(item_key).child('pending_eta_request').set(true)
            firebase.database().ref('purchase_orders').child(email.po).child('items').child(item_key).child('eta_last_requested').set(timestamp)
            firebase.database().ref('purchase_orders').child(email.po).child('items').child(item_key).child('eta_escalation_requests').push({'requested_by': email.requested_by, 'requested_date': timestamp, 'source': 'manual'})
            firebase.database().ref('purchase_orders').child(email.po).child('items').child(item_key).child('eta_escalation_requests_cnt').transaction(function(requests) { return (requests || 0) + 1 })
          }
          firebase.database().ref('email_success_log').child(today_string).push({email: email, timestamp: timestamp, method: 'eta_request_from_item_popup'})
        }else{
          console.log(email.po + ' ' + response)
          firebase.database().ref('email_failure_log').child(today_string).push({email: email, timestamp: timestamp, method: 'eta_request_from_item_popup', error: response})
        }
      })
      .catch(function (error) {
        console.log(email.po)
        console.log(error)
        firebase.database().ref('email_failure_log').child(today_string).push({email: email, timestamp: Date.now(), method: 'eta_request_from_item_popup', error: error.message})
      });
    },
    poConfirmationRequestEmail(email){
      var subject = 'PO Confirmation Request - ' + email.vendor
      var html = 'Hello, please confirm you received our PO by ' + '<a href="' + this.vendor_url + email.vendor_page + '">clicking here</a>' + '. DO NOT REPLY.<br><br>Thanks,<br><br>Purchasing Team<br>Riverhawk Industrial Supply'

      var to_address_arr = email.to.replace(/ /g, '').split(';')
      var email_to_no_duplicates_arr = to_address_arr.filter(function(item, pos) {
          return to_address_arr.indexOf(item) == pos && item.indexOf('emailmissing') == -1;
      })
      email.to = email_to_no_duplicates_arr.join(';')

      if(email.to === ''){
        return
      }

      var encodedSubject = encodeURIComponent(subject);
      var encodedHtml = encodeURIComponent(html);
      var url = this.url+'?to=' + email.to + '&from=' + this.from +'&subject='+encodedSubject + '&html='+encodedHtml

      var confirmation_requested_timestamp = Date.now()
      var today_string = Moment(confirmation_requested_timestamp).format('YYYY-MM-DD')

      axios.post(url, {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'})
      .then(function (response) {
        if(response.data.success){
          console.log('PO' + email.po + ' ' + response.data.success.message)
          firebase.database().ref('purchase_orders').child(email.po + '/action_vendor_po_conf_response').set('Pending')
          firebase.database().ref('purchase_orders').child(email.po + '/po_confirmation_last_requested').set(confirmation_requested_timestamp)
          firebase.database().ref('purchase_orders').child(email.po + '/po_confirmation_request_history').push({'requested_by': email.requested_by, 'requested_date': confirmation_requested_timestamp})
          firebase.database().ref('purchase_orders').child(email.po + '/action_po_conf_request_cnt').transaction(function(requests) { return (requests || 0) + 1 })
          firebase.database().ref('email_success_log').child(today_string).push({email: email, timestamp: confirmation_requested_timestamp, method: 'initial_po_confirmation_request_from_dashboard'})
        }else{
          console.log(response)
          firebase.database().ref('email_failure_log').child(today_string).push({email: email, timestamp: confirmation_requested_timestamp, method: 'initial_po_confirmation_request_from_dashboard', error: 'check emailMixin file'})
        }
      })
      .catch(function (error) {
        console.log('axios error for po: ' + email.po + ' '  + error)
        firebase.database().ref('email_failure_log').child(today_string).push({email: email, timestamp: confirmation_requested_timestamp, method: 'initial_po_confirmation_request_from_dashboard'})
      });
    },
    poConfirmationRequestEscalationEmail(email){
      var subject = 'ESCALATION: PO Confirmation Request - ' + email.vendor
      var html = 'Hello, we are reaching out again. Can you please confirm you received our PO by ' + '<a href="' + this.vendor_url + email.vendor_page + '">clicking here</a>' + '. DO NOT REPLY.<br><br>Thanks,<br><br>Purchasing Team<br>Riverhawk Industrial Supply'

      var to_address_arr = email.to.replace(/ /g, '').split(';')
      var email_to_no_duplicates_arr = to_address_arr.filter(function(item, pos) {
          return to_address_arr.indexOf(item) == pos && item.indexOf('emailmissing') == -1;
      })
      email.to = email_to_no_duplicates_arr.join(';')

      if(email.to === ''){
        return
      }
      var encodedSubject = encodeURIComponent(subject);
      var encodedHtml = encodeURIComponent(html);
      var url = this.url+'?to=' + email.to + '&from=' + this.from +'&subject='+encodedSubject + '&html='+encodedHtml
      var confirmation_requested_timestamp = Date.now()
      var today_string = Moment(confirmation_requested_timestamp).format('YYYY-MM-DD')

      axios.post(url, {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'})
      .then(function (response) {
        if(response.data.success){
          console.log('PO' + email.po + ' ' + response.data.success.message)
          firebase.database().ref('purchase_orders').child(email.po + '/action_vendor_po_conf_response').set('Pending')
          firebase.database().ref('purchase_orders').child(email.po + '/po_confirmation_last_requested').set(confirmation_requested_timestamp)
          firebase.database().ref('purchase_orders').child(email.po + '/po_confirmation_request_history').push({'requested_by': email.requested_by, 'requested_date': confirmation_requested_timestamp})
          firebase.database().ref('purchase_orders').child(email.po + '/action_po_conf_request_cnt').transaction(function(requests) { return (requests || 0) + 1 })
          firebase.database().ref('email_success_log').child(today_string).push({email: email, timestamp: confirmation_requested_timestamp, method: 'po_confirmation_request_again_from_dashboard'})
        }else{
          console.log(response)
          firebase.database().ref('email_failure_log').child(today_string).push({email: email, timestamp: confirmation_requested_timestamp, method: 'po_confirmation_request_again_from_dashboard',error: 'check emailMixin file'})
        }
      })
      .catch(function (error) {
        console.log('axios error for po: ' + email.po + ' '  + error)
        firebase.database().ref('email_failure_log').child(today_string).push({email: email, timestamp: confirmation_requested_timestamp, method: 'po_confirmation_request_again_from_dashboard'})
      });
    },
    poDisputeEmail(email){
      // DisputePurchaseOrderPopUp.vue
      var subject = 'PO Dispute - ' + email.po + ' (' + email.vendor + ')'
      var html = '<strong>PO Disputed:</strong> ' + email.po + '<br><strong>Vendor:</strong> ' + email.vendor +'<br><br><strong>Note:</strong> ' + email.note + '<br><strong>Submitted By:</strong> ' + email.submitted_by + '<br><strong>Submitted By Email:</strong> ' + email.submitted_by_email

      var encodedSubject = encodeURIComponent(subject);
      var encodedHtml = encodeURIComponent(html);
      var url = this.url+'?to=' + this.dispute_contact + '&from=' + this.from +'&subject='+encodedSubject + '&html='+encodedHtml

      axios.post(url, {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'})
      .then(function (response) {
        if(response.data.success){
          console.log(response.data.success.message)
        }else{
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      });
    },
    itemUTPEmail(email){

      var subject = 'Item ETA is UTP - ' + email.item + ' (' + email.vendor + ')'
      var html = '<strong>Item ETA marked UTP:</strong> ' + email.item + '<br><strong>PO:</strong>' + email.po + '<br><strong>Vendor:</strong> ' + email.vendor +'<br><strong>Note:</strong> ' + email.note + '<br><strong>Submitted By:</strong> ' + email.submitted_by + '<br><strong>Submitted By Email:</strong> ' + email.submitted_by_email

      var encodedSubject = encodeURIComponent(subject);
      var encodedHtml = encodeURIComponent(html);
      var url = this.url + '?to=' + this.utp_contact + '&from=' + this.from + '&subject=' + encodedSubject + '&html=' + encodedHtml;

      axios.post(url, {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'})
      .then(function (response) {
        if(response.data.success){
          console.log(response.data.success.message)
        }else{
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      });
    },
    sharedNoteEmail(email){
      var subject = 'Shared Note from ' + email.vendor + ' - PO: ' + email.po
      var html = '<strong>Vendor:</strong> ' + email.vendor + '<br><strong>PO: </strong>' + email.po + '<br><strong>Item: </strong> ' + email.item +'<br><strong>Shared Note: </strong> ' + email.note
      var encodedSubject = encodeURIComponent(subject);
      var encodedHtml = encodeURIComponent(html);
      var url = this.url + '?to=' + this.shared_note_contact + '&from=' + this.from + '&subject=' + encodedSubject + '&html=' + encodedHtml;

      axios.post(url, {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'})
      .then(function (response) {
        if(response.data.success){
          console.log(response.data.success.message)
        }else{
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      });
    },
    feedbackEmail(email){
      var subject = 'NEST Customer Feedback'
      var html = 'Customer: ' + email.customer + '<br>'
      html += 'Sent By: ' + email.email + '<br>'
      html += 'Thumb: ' + email.thumb + '<br>'
      html += 'Feedback: ' + email.note + '<br>'

      email.to = 'christopher.w.finlayson@gmail.com;sfinlayson@riverhawkindustrialsupply.com;sstrother@riverhawkindustrialsupply.com'

      var encodedSubject = encodeURIComponent(subject);
      var encodedHtml = encodeURIComponent(html);
      var url = this.url + '?to=' + email.to + '&from=' + this.from + '&subject=' + encodedSubject + '&html=' + encodedHtml

      axios.post(url, {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'})
      .then(function (response) {
        if(response.data.success){
          console.log(response.data.success.message)
          alert('Thank you for your feedback!')
        }else{
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      });
    },
    customerInquireEmail(email){
      var subject = 'Backorder Inquiry from ' + email.customer
      var html = 'Customer: ' + email.customer + '<br>'
      html += 'Order Number: ' + email.order_number + '<br>'
      html += 'Product Number: ' + email.product_number + '<br>'
      html += 'Inquiry sent by name: ' + email.provided_name + '<br>'
      html += 'Inquiry sent by email: ' + email.provided_email + '<br>'
      html += 'Inquiry Note: ' + email.provided_note + '<br>'

      var encodedSubject = encodeURIComponent(subject);
      var encodedHtml = encodeURIComponent(html);
      var url = this.url + '?to=' + email.to + '&from=' + this.from + '&subject=' + encodedSubject + '&html=' + encodedHtml

      axios.post(url, {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'})
      .then(function (response) {
        if(response.data.success){
          console.log(response.data.success.message)
        }else{
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      });
    }
  }
}
