<template>
  <div class="customer_backorders">
    <div style="font-size: 22px; color: gray;padding:10px">Customers</div>

    <div v-if="customers_table_data.length">
      <v-row style="width:750px;margin:0px 0px">
        <v-col cols="6">
          <div style="width:350px;">
            <v-text-field label="Search Customers..." variant="solo" density="comfortable" hide-details="true" @input="debouncedSearch($event)"></v-text-field>
          </div>
        </v-col>
      </v-row>

      <div class="table-container">
          <Vue3EasyDataTable
            :headers="headers"
            :items="customers_table_data"
            :search-value="searchValue"
            :sort-by="sortBy"
            :sort-type="sortType"
            :table-height="table_height"
            :rows-per-page="250"
            :rows-items="[250, 500, 1000, 5000]"
            :hide-rows-per-page="false"
            :hide-footer="false"
          >

          <template #item-customer_name="item">
            <router-link :to="{ name: 'customer', params: {customerpageid: item.page}}" target="_blank" style="color:#ff7214">{{item.customer_name}}</router-link>
          </template>

          <template #item-child_ar_accounts_sortable="item">
            <div v-for="ca in item.child_ar_accounts_arr">
              <div>{{db.customers['customer_'+ca].customer_name}} ({{ca}})</div>
            </div>
          </template>

          <template #item-has_backorders="item">
            {{hasBackorders(item)}}
          </template>

        </Vue3EasyDataTable>
      </div>

    </div>
  </div>
</template>

<script>

import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { getDatabase, ref, query, orderByChild, equalTo, onChildAdded, onChildChanged, onChildRemoved } from 'firebase/database';
import { debounce } from 'lodash';

export default {
  name: 'Customers',
  components: {Vue3EasyDataTable},
  data(){
    return {
      filelist: [],
      searchValue: '',
      sortBy: 'customer_name',
      sortType: 'asc',
      table_height: 400,
      headers: [
        { text: "Customer Number", value: "customer_number", sortable: true },
        { text: "Customer Name", value: "customer_name", sortable: true },
        { text: "Master AR Account", value: "master_ar_account", sortable: true },
        { text: "Salesperson Code", value: "salesperson_code", sortable: true },
        { text: "Credit Status", value: "credit_status", sortable: true },
        { text: "Account Manager", value: "account_manager", sortable: true },
        { text: "Dedicated ETA Manager?", value: "has_dedicated_eta_manager", sortable: true },
        { text: "ETA Contact Emails", value: "eta_contact_emails", sortable: true },
        { text: "Collections Inquiry Email", value: "collections_inquiry_emails", sortable: true },
        { text: "Send ETA Update Every _____ Days", value: "send_eta_update_every_x_days", sortable: true },
        { text: "Child AR Accounts", value: "child_ar_accounts_sortable", sortable: true },
        { text: "Has Backorders", value: "has_backorders", sortable: false },
      ],
      customers_table_data: []
    }
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },
  beforeMount(){
    if(this.user === 'vendor' || !this.user){
      this.$router.push('/')
    }    
    this.$store.state.isLoading = true
  },
  mounted() {
    setTimeout(() => {
      const db = getDatabase();
      const customersRef = ref(db, 'customers');

      this.onChildAddedListener = onChildAdded(customersRef, (snapshot) => {
        const customer = snapshot.val();
        customer.id = snapshot.key;
        customer.child_ar_accounts_sortable = customer.child_ar_accounts ? customer.child_ar_accounts.sort().join(', ') : '';
        customer.child_ar_accounts_arr = customer.child_ar_accounts ? customer.child_ar_accounts.sort() : [];
        
        if (customer.credit_status !== 'I') {
          this.customers_table_data.push(customer);
        }
        
        this.$store.state.isLoading = false;
      });

      this.onChildChangedListener = onChildChanged(customersRef, (snapshot) => {
        const customer = snapshot.val();
        customer.id = snapshot.key;
        customer.child_ar_accounts_sortable = customer.child_ar_accounts ? customer.child_ar_accounts.sort().join(', ') : '';
        customer.child_ar_accounts_arr = customer.child_ar_accounts ? customer.child_ar_accounts.sort() : [];
        
        const index = this.customers_table_data.findIndex((i) => i.id === customer.id);
        
        if (customer.credit_status === 'I') {
          if (index !== -1) {
            this.customers_table_data.splice(index, 1);
          }
        } else {
          if (index !== -1) {
            this.customers_table_data.splice(index, 1, customer);
          } else {
            this.customers_table_data.push(customer);
          }
        }
      });

      this.onChildRemovedListener = onChildRemoved(customersRef, (snapshot) => {
        const id = snapshot.key;
        const index = this.customers_table_data.findIndex((customer) => customer.id === id);
        if (index !== -1) {
          this.customers_table_data.splice(index, 1);
        }
      });

    }, 1000);
  },
  beforeUnmount() {
    if (this.onChildAddedListener) {
      this.onChildAddedListener();
    }
    if (this.onChildChangedListener) {
      this.onChildChangedListener();
    }
    if (this.onChildRemovedListener) {
      this.onChildRemovedListener();
    }
  },
  computed: {
    ...mapGetters(['user','db']),
    customersWithBackorders() {
      const customers = [];
      for (var backorder in this.db.backorders) {
        var backorder = this.db.backorders[backorder]
        if(customers.indexOf(backorder.customer_number) === -1 && !backorder.completed){
          customers.push(backorder.customer_number);
        }
      }
      return customers;
    }
  },
  methods: {
    hasBackorders(customer){
      var arr = JSON.parse(JSON.stringify(customer.child_ar_accounts_arr))
      arr.push(customer.customer_number)
      for(var i = 0; i < arr.length; i++){
        if(this.customersWithBackorders.indexOf(arr[i]) !== -1 ){
          return 'Y'
        }
      }
      return ''
    },
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 260
    },
    debouncedSearch: debounce(function(event) {
      this.searchValue = event.target._value
    }, 500),
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .table-container{
    position: relative;
    top: 5px;
    margin:0px 10px;
  }
  >>>td{
    max-width: 250px; 
    word-wrap: break-word;
  }
</style>
