import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import VendorView from '../views/VendorView.vue'
import CustomerView from '../views/CustomerView.vue'
import PurchasingDashboardView from '../views/PurchasingDashboardView.vue'
import PurchaseOrdersMasterView from '../views/PurchaseOrdersMasterView.vue'
import CompletedPurchaseOrdersView from '../views/CompletedPurchaseOrdersView.vue'
import EtaMasterView from '../views/EtaMasterView.vue';
import VendorsMasterView from '../views/VendorsMasterView.vue';
import DataAdministrationView from '../views/DataAdministrationView.vue';
import UsersAdministrationView from '../views/UsersAdministrationView.vue';
import AnalyticsAdministrationView from '../views/AnalyticsAdministrationView.vue';
import ProactiveEtasView from '../views/ProactiveEtasView.vue'
import NonCurrentEtasView from '../views/NonCurrentEtasView.vue';
import CustomersView from '../views/CustomersView.vue';
import ArMasterView from '../views/ArMasterView.vue';
import CollectionsCandidatesView from '../views/CollectionsCandidatesView.vue';
import CustomerArView from '../views/CustomerArView.vue'
import BackorderListView from '../views/BackorderListView.vue'
import ShipmentTrackerView from '../views/ShipmentTrackerView.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/vendor/:vendorpageid',
    name: 'vendor',
    component: VendorView
  },
  {
    path: '/customer/:customerpageid',
    name: 'customer',
    component: CustomerView
  },
  {
    path: '/customer-ar/:customerarpageid',
    name: 'customer-ar',
    component: CustomerArView
  },
  {
    path: '/purchasing-dashboard',
    name: 'purchasing-dashboard',
    component: PurchasingDashboardView
  },
  {
    path: '/proactive-etas',
    name: 'proactive-etas',
    component: ProactiveEtasView
  },
  {
    path: '/purchase-orders-master',
    name: 'purchase-orders-master',
    component: PurchaseOrdersMasterView
  },
  {
    path: '/completed-purchase-orders',
    name: 'completed-purchase-orders',
    component: CompletedPurchaseOrdersView
  },  
  {
    path: '/eta-master',
    name: 'eta-master',
    component: EtaMasterView
  },
  {
    path: '/customers',
    name: 'customers',
    component: CustomersView
  },
  {
    path: '/vendors-master',
    name: 'vendors-master',
    component: VendorsMasterView
  },
  {
    path: '/data-administration',
    name: 'data-administration',
    component: DataAdministrationView
  },
  {
    path: '/users-administration',
    name: 'users-administration',
    component: UsersAdministrationView
  },
  {
    path: '/analytics-administration',
    name: 'analytics-administration',
    component: AnalyticsAdministrationView
  },  
  {
    path: '/non-current-etas',
    name: 'non-current-etas',
    component: NonCurrentEtasView
  },
  {
    path: '/ar-master',
    name: 'ar-master',
    component: ArMasterView
  },
  {
    path: '/collections-candidates',
    name: 'collections-candidates',
    component: CollectionsCandidatesView
  },
  {
    path: '/backorder-list',
    name: 'backorder-list',
    component: BackorderListView
  },
        {
    path: '/shipment-tracker',
    name: 'shipment-tracker',
    component: ShipmentTrackerView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
