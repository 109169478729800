<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="90vw">
      <v-card-text>
        <v-card-title style="margin-left:-15px">Please provide the ETA or let us know if you are unable to provide one at this time.</v-card-title>
        <div style="font-weight:bold">PO: {{po}}</div>

        <div style="font-weight:bold;margin-bottom:10px">
          Manufacturer Product Number: {{item.manufacturer_part_number}}<br>
          Vendor Product Number: {{item.vendor_part_number}}<br>
          Product Description: {{item.product_description}}
        </div>

        <div>*ETA</div>
        <v-row style="max-width:520px;height:77px">
          <v-col cols="5">
            <div v-if="eta == 'UTP'" style="margin-top:5px;font-size:18px;font-style:italic">Unable To Provide</div>
            <datepicker v-model="eta" format="M/dd/yyyy" name="index" position="left" style="margin-bottom:15px;" v-if="eta !== 'UTP'"></datepicker>
          </v-col>
          <v-col cols="2"><div style="text-align:center;padding-top:5px"> - or - </div></v-col>
          <v-col cols="5" style="position:relative">
            <v-btn size="small" v-if="eta !== 'UTP'" style="background:#ff7214;color:white;margin-top:4px" @click="eta = 'UTP'">UNABLE TO PROVIDE ETA</v-btn>
            <v-btn size="small" v-if="eta === 'UTP'" style="background:#ff7214;color:white;width:186px;margin-top:4px" @click="eta = '0/0/00'">SET ETA</v-btn>
          </v-col>
        </v-row>

        <div>*Your Name</div><v-text-field variant="outlined" placeholder="Your Name" v-model="submitted_by"></v-text-field>

        <div>*Your Email</div><v-text-field variant="outlined" placeholder="Your Email" v-model="submitted_by_email"></v-text-field>

        <div><span v-if="eta === 'UTP'">*</span>Shared Note</div><v-textarea variant="outlined" placeholder="Shared Note" v-model="shared_note"></v-textarea>

        <hr style="margin-bottom:10px">

        <div>Shipping Company</div><v-text-field variant="outlined" placeholder="Shipping Company" v-model="shipping_company"></v-text-field>

        <div>Tracking Number</div><v-text-field variant="outlined" placeholder="Tracking Number" v-model="tracking_number"></v-text-field>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="saveItemInfo()" :disabled="!submitted_by.trim() || !submitted_by_email.trim() || eta === '0/0/00'">SAVE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Datepicker from 'vuejs3-datepicker';
import { emailMixin } from '../mixins/emailMixin.js'

export default {
  name: 'VendorUpdateItemInfoPopUp',
  components: {Datepicker},
  mixins: [emailMixin],
  props:{
    po: String,
    itemkey: String
  },
  data(){
    return{
      dialog: true,
      item: null,
      eta: '0/0/00',
      submitted_by: '',
      submitted_by_email: '',
      shipping_company: '',
      tracking_number: '',
      shared_note: ''
    }
  },
  beforeMount(){
    var item_clone = JSON.parse(JSON.stringify( this.db.purchase_orders[this.po].items[this.itemkey] ))
    this.item = item_clone
    this.shipping_company = item_clone.shipping_company
    this.tracking_number = item_clone.tracking_number
  },
  computed: {
    ...mapGetters(['user','db']),
  },
  methods:{
    saveItemInfo(){

      var self = this
      var formatted_eta = self.eta !== 'UTP' ? Moment(self.eta).format('M/DD/YY') : 'UTP'
      var eta_set_timestamp = Date.now()

      if(formatted_eta === 'UTP' && self.shared_note.trim() === ''){
        alert('Please provide a note explaining why you are unable to provide ETA for this line item.')
        return
      }

      if(formatted_eta === 'UTP'){
          var email = {
            po: self.po,
            item: self.item.product,
            vendor: self.db.purchase_orders[self.po].vendor_name,
            note: JSON.stringify(self.shared_note).replace(/\\n/g, '<br>'),
            submitted_by: self.submitted_by,
            submitted_by_email: self.submitted_by_email,
          }
          self.itemUTPEmail(email)
      }

      if(self.shared_note.trim() !== '' && formatted_eta !== 'UTP'){
          var email = {
            po: self.po,
            item: self.item.product,
            vendor: self.db.purchase_orders[self.po].vendor_name,
            note: JSON.stringify(self.shared_note).replace(/\\n/g, '<br>'),
          }
          self.sharedNoteEmail(email)        
      }

      firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('eta').set( formatted_eta )
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('pending_eta_request').set(false)
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('eta_last_provided').set(eta_set_timestamp)
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('eta_escalation_requests_cnt').set(0)
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('eta_history').push( {'eta': formatted_eta, 'date_provided': eta_set_timestamp} )
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('approx_eta_set').set(false)
      if(self.shared_note.trim() !== ''){
        firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('shared_notes').push( {note: self.shared_note, submitted_by: self.submitted_by, submitted_date: Date.now()} )
      }

      firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('shipping_company').set( self.shipping_company ).then(function(){
        firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('tracking_number').set( self.tracking_number ).then(function(){
          self.$emit('close')
        })
      })

    }
  }
}
</script>

<style scoped>
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
  }
  .vuejs3-datepicker__icon{
    padding-top: 2px;
  }
  .vuejs3-datepicker__value{
    padding: 5px 15px;
  }
  .vuejs3-datepicker__calendar{

  }
  .vuejs3-datepicker__calendar-topbar{
    display: none;
  }
</style>


