<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="540px" style="min-height:600px">
      <v-card-text>
      
        <v-card-title style="margin-left:-15px">Bulk Update ETAs for PO {{po}}</v-card-title>

        <v-row style="max-width:520px;height:77px">
          <v-col cols="12">
            <div v-if="eta == 'UTP'" style="margin-top:5px;font-size:18px;font-style:italic">Unable To Provide</div>
            <div v-if="eta == 'TBD'" style="margin-top:5px;font-size:18px;font-style:italic">TBD</div>
            <datepicker v-model="eta" format="M/dd/yyyy" name="index" style="margin-bottom:5px;" v-if="eta !== 'UTP' && eta !== 'TBD'"></datepicker>
            <div><v-btn size="small" v-if="eta !== 'UTP' && eta !== 'TBD'" style="background:#ff7214;color:white;margin-top:2px;width:200px" @click="eta = 'UTP'">UNABLE TO PROVIDE ETA</v-btn></div>
            <div><v-btn size="small" v-if="eta !== 'UTP' && eta !== 'TBD'" style="background:#ff7214;color:white;margin-top:10px;width:200px" @click="eta = 'TBD'">TBD</v-btn></div>
            <v-btn size="small" v-if="eta === 'UTP' || eta === 'TBD'" style="background:#ff7214;color:white;width:186px;margin-top:10px;width:200px" @click="eta = '0/0/00'">SET ETA</v-btn>
          </v-col>
        </v-row>

        <div style="position: relative;top:80px;max-height:350px;overflow:scroll;border: 1px solid lightgray;border-radius: 2px;">
          <table>
            <tbody>
              <tr v-for="item in items">
                <td style="width:40px;line-height:100%"><v-checkbox v-model="selected_items" :value="item.item_key" density="compact" color="#ff7214" hide-details></v-checkbox></td>
                <td style="width:150px;font-size:12px;line-height:100%">{{item.product}}</td>
                <td style="width:150px;font-size: 10px;font-style: italic;line-height:100%">Current ETA: {{item.eta}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </v-card-text>

      <v-card-actions>
        <v-btn size="x-small" style="background:#ff7214;color:white;margin-left:15px" @click="toggleSelectAll($event)">{{allSelected ? 'Unselect All' : 'Select All'}}</v-btn>
        <div style="margin-left: 5px;font-size: 12px">Items Selected: {{selected_items.length}}</div>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="saveBulkETA()" :disabled="isSaveDisabled">SAVE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Datepicker from 'vuejs3-datepicker';

export default {
  name: 'UpdateEtaPopup',
  components: {Datepicker},
  props:{
    po: String
  },
  data(){
    return{
      dialog: true,
      items: null,
      eta: '0/0/00',
      selected_items: []
    }
  },
  beforeMount(){
    var items_clone = JSON.parse(JSON.stringify( this.db.purchase_orders[this.po].items))
    this.items = items_clone
  },
  computed: {
    ...mapGetters(['user','db']),
    isSaveDisabled() {
      return this.eta === '0/0/00' || this.selected_items.length === 0;
    },
    allSelected(){
      if(this.selected_items.length === Object.keys(this.items).length){
        return true
      }else{
        return false
      }
    }  
  },
  methods:{
    toggleSelectAll() {
      event.preventDefault();
      if(this.allSelected){
        this.selected_items = [];
      }else{
        this.selected_items = Object.values(this.items).map(item => item.item_key);
      }
    },       
    saveBulkETA(){
      var self = this
      var formatted_eta = (self.eta !== 'UTP' && self.eta !== 'TBD') ? Moment(self.eta).format('M/DD/YY') : self.eta
      var eta_set_timestamp = Date.now()
      self.$store.state.isLoading = true
      
      for(var i = 0; i < this.selected_items.length;i++){
        var itemkey = this.selected_items[i]
        self.updateItemEta(itemkey,formatted_eta,eta_set_timestamp)
      }

        setTimeout(function(){
          self.$store.state.isLoading = false
          self.$emit('close')
        },3000)
      
    },
    updateItemEta(itemkey,formatted_eta,eta_set_timestamp){
      var self = this
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(itemkey).child('eta').set( formatted_eta )
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(itemkey).child('eta_last_provided').set(eta_set_timestamp)
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(itemkey).child('pending_eta_request').set(false)
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(itemkey).child('eta_escalation_requests_cnt').set(0)
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(itemkey).child('eta_history').push( {'eta': formatted_eta, 'date_provided': eta_set_timestamp} )
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(itemkey).child('approx_eta_set').set(false)      
    }
  }
}
</script>

<style scoped>
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
  }
  .vuejs3-datepicker__icon{
    padding-top: 2px;
  }
  .vuejs3-datepicker__value{
    padding: 5px 15px;
  }
  .vuejs3-datepicker__calendar{

  }
  .vuejs3-datepicker__calendar-topbar{
    display: none;
  }
</style>


