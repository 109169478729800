<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="540px" style="min-height:500px">
      <v-card-text >
      
        <v-card-title style="margin-left:-15px">ETA for Item {{item.manufacturer_part_number}} in PO {{po}}</v-card-title>

        <v-row style="max-width:520px;height:77px">
          <v-col cols="12">
            <div v-if="eta == 'UTP'" style="margin-top:5px;font-size:18px;font-style:italic">Unable To Provide</div>
            <div v-if="eta == 'TBD'" style="margin-top:5px;font-size:18px;font-style:italic">TBD</div>
            <datepicker v-model="eta" format="M/dd/yyyy" name="index" position="left" style="margin-bottom:15px;" v-if="eta !== 'UTP' && eta !== 'TBD'"></datepicker>
            <div><v-btn size="small" v-if="eta !== 'UTP' && eta !== 'TBD'" style="background:#ff7214;color:white;margin-top:4px;width:200px" @click="eta = 'UTP'">UNABLE TO PROVIDE ETA</v-btn></div>
            <div><v-btn size="small" v-if="eta !== 'UTP' && eta !== 'TBD'" style="background:#ff7214;color:white;margin-top:22px;width:200px" @click="eta = 'TBD'">TBD</v-btn></div>
            <v-btn size="small" v-if="eta === 'UTP' || eta === 'TBD'" style="background:#ff7214;color:white;width:186px;margin-top:20px;width:200px" @click="eta = '0/0/00'">SET ETA</v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="saveItemInfo()" :disabled="eta === '0/0/00'">SAVE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Datepicker from 'vuejs3-datepicker';

export default {
  name: 'UpdateEtaPopup',
  components: {Datepicker},
  props:{
    po: String,
    itemkey: String
  },
  data(){
    return{
      dialog: true,
      item: null,
      eta: '0/0/00'
    }
  },
  beforeMount(){
    var item_clone = JSON.parse(JSON.stringify( this.db.purchase_orders[this.po].items[this.itemkey] ))
    this.item = item_clone
  },
  computed: {
    ...mapGetters(['user','db']),
  },
  methods:{
    saveItemInfo(){
      var self = this
      var formatted_eta = (self.eta !== 'UTP' && self.eta !== 'TBD') ? Moment(self.eta).format('M/DD/YY') : self.eta
      var eta_set_timestamp = Date.now()
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('eta').set( formatted_eta )
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('eta_last_provided').set(eta_set_timestamp)
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('pending_eta_request').set(false)
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('eta_escalation_requests_cnt').set(0)
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('eta_history').push( {'eta': formatted_eta, 'date_provided': eta_set_timestamp} )
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(self.itemkey).child('approx_eta_set').set(false)
      self.$emit('close')
    }
  }
}
</script>

<style scoped>
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
  }
  .vuejs3-datepicker__icon{
    padding-top: 2px;
  }
  .vuejs3-datepicker__value{
    padding: 5px 15px;
  }
  .vuejs3-datepicker__calendar{

  }
  .vuejs3-datepicker__calendar-topbar{
    display: none;
  }
</style>


